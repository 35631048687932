<template>
  <!-- 搜索 -->
  <el-dialog
    :visible="dialogElementShow.searchCtrl"
    :close-on-click-modal="false"
    @close="dialog"
    id="xppSearchDialog"
    width="90%"
  >
    <div class="xpp-search-input" slot="title">
      <span class="xpp-search-label">搜索：</span>
      <form class="xpp-search-form" @submit="searchSubmit">
        <el-input
          v-model="searchForm.text"
          suffix-icon="el-icon-search"
          placeholder="请输入搜索内容"
          ref="xppConSearch"
        >
        </el-input>
      </form>
    </div>
    <el-row v-loading="searchLoad">
      <el-col :span="14">
        <div class="xpp-search-paper-container">
          <h2 class="xpp-search-tit">PAPER</h2>
          <div class="xpp-search-con">
            <template v-if="searchResult.page">
              <div
                class="xpp-search-item xpp-searchitem-paper display-flex"
                v-for="item in searchResult.page"
                :key="item.id"
                :data-id="item.id"
                @click="routerJumpPaper(item.parentId)"
              >
                <div class="xpp-search-paper-thumbnail"></div>
                <div class="xpp-search-paper-inner">
                  <div class="text-overflow" v-html="item.label"></div>
                </div>
              </div>
            </template>
            <div class="xpp-search-none" v-else>
              <p v-show="searchForm.submitState">
                搜索“<span class="xpp-search-tag">{{
                  searchForm.searchText
                }}</span
                >”时没有找到任何结果
              </p>
            </div>
          </div>
        </div>
        <div class="xpp-search-other-container">
          <el-tabs v-model="activeName">
            <el-tab-pane label="全部" name="all"></el-tab-pane>
            <el-tab-pane
              :label="'项目' + tabsLabel('project')"
              name="project"
            ></el-tab-pane>
            <el-tab-pane
              :label="'便签' + tabsLabel('note')"
              name="note"
            ></el-tab-pane>
            <el-tab-pane
              :label="'图片' + tabsLabel('image')"
              name="image"
            ></el-tab-pane>
            <el-tab-pane
              :label="'WEB链接' + tabsLabel('link')"
              name="link"
            ></el-tab-pane>
            <el-tab-pane
              :label="'文件' + tabsLabel('file')"
              name="file"
            ></el-tab-pane>
            <el-tab-pane
              :label="'文件夹' + tabsLabel('dir')"
              name="dir"
            ></el-tab-pane>
          </el-tabs>
          <div class="xpp-search-other-body" @mousemove="mousemoveNode">
            <div
              class="xpp-search-none"
              v-show="searchForm.submitState && searchNull"
            >
              <p>
                搜索“<span class="xpp-search-tag">{{
                  searchForm.searchText
                }}</span
                >”时没有找到任何结果
              </p>
            </div>
            <template v-if="activeName === 'project' || activeName === 'all'">
              <div
                class="xpp-search-other-item xpp-searchitem-dir"
                v-for="item in searchResult.project"
                :key="item.id"
                @click="routerJumpElement(item)"
              >
                <div></div>
                <div class="text-overflow" v-html="item.label"></div>
              </div>
            </template>
            <template v-if="activeName === 'note' || activeName === 'all'">
              <div
                class="xpp-search-other-item  xpp-searchitem-note"
                v-for="item in searchResult.note"
                :key="item.id"
                :style="item.backgroundColor"
                :data-group="item.groupName"
                @click="routerJumpElement(item)"
              >
                <div v-html="item.label"></div>
              </div>
            </template>
            <template v-if="activeName === 'image' || activeName === 'all'">
              <div
                class="xpp-search-other-item xpp-searchitem-image"
                v-for="item in searchResult.image"
                :key="item.id"
                :data-group="item.groupName"
                @click="routerJumpElement(item)"
              >
                <div
                  :style="{
                    backgroundImage:
                      item.fileInfo && `url(${item.fileInfo.src})`
                  }"
                ></div>
              </div>
            </template>
            <template v-if="activeName === 'link' || activeName === 'all'">
              <div
                class="xpp-search-other-item xpp-searchitem-link"
                v-for="item in searchResult.link"
                :key="item.id"
                :data-group="item.groupName"
                @click="routerJumpElement(item)"
              >
                <div
                  class="xpp-searchitem-link-tit text-overflow"
                  v-html="item.label"
                ></div>
                <div class="xpp-searchitem-link-con">
                  <span class="xpp-searchitem-link-origin text-overflow">{{
                    item.link | linkOrigin
                  }}</span>
                </div>
              </div>
            </template>
            <template v-if="activeName === 'file' || activeName === 'all'">
              <div
                class="xpp-search-other-item xpp-searchitem-file"
                v-for="item in searchResult.file"
                :key="item.id"
                :data-group="item.groupName"
                @click="routerJumpElement(item)"
              >
                <div
                  class="xpp-searchitem-file-tit text-overflow"
                  v-html="item.label"
                ></div>
                <div
                  class="xpp-searchitem-file-con xpp-paper-files-type"
                  :data-file="item.fileInfo.fileType"
                >
                  <span class="xpp-searchitem-file-size">{{
                    item.fileInfo.size | fileSize
                  }}</span>
                  <span
                    class="xpp-searchitem-file-download text-overflow"
                    @click.stop="fileDownload(item.fileInfo)"
                    >下载文件</span
                  >
                </div>
              </div>
            </template>
            <template v-if="activeName === 'dir' || activeName === 'all'">
              <div
                class="xpp-search-other-item xpp-searchitem-dir"
                v-for="item in searchResult.dir"
                :key="item.id"
                :data-group="item.groupName"
                @click="routerJumpElement(item)"
              >
                <div></div>
                <div class="text-overflow" v-html="item.label"></div>
              </div>
            </template>
          </div>
        </div>
      </el-col>
      <el-col :span="8" :offset="2">
        <h2 class="xpp-search-tit">评论</h2>
        <div class="xpp-search-con">
          <template v-if="searchResult.comment">
            <div
              class="xpp-searchitem-comment"
              v-for="item in searchResult.comment"
              :key="item.id"
            >
              <div class="display-flex">
                <div class="display-flex flex-start">
                  <div
                    class="xpp-user-photo"
                    :style="item.userInfo.headImg | styleUserPhoto"
                  ></div>
                  <div>{{ item.userInfo.nickName }}</div>
                </div>
                <div class="xpp-searchitem-c-time">{{ item.createDate }}</div>
              </div>
              <div
                class="xpp-searchitem-c-con"
                v-html="item.label"
                @click="routerJumpElement(item)"
              ></div>
              <div class="xpp-searchitem-c-footer">
                <span class="text-overflow">#{{ item.groupName }}</span>
              </div>
            </div>
          </template>
          <div class="xpp-search-none" v-else>
            <p v-show="searchForm.submitState">
              搜索“<span class="xpp-search-tag">{{
                searchForm.searchText
              }}</span
              >”时没有找到任何结果
            </p>
          </div>
        </div>
      </el-col>
    </el-row>
    <div
      class="xpp-search-tooltip"
      :class="xppTooltip"
      :style="searchTooltip"
      ref="tooltip"
    >
      #{{ searchTooltipText || "未知来源" }}
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { ossDownload } from "@plugins/aliyunOss";

export default {
  name: "XppSearchDialog",
  computed: {
    xppTooltip() {
      return {
        "xpp-tooltip-show": this.tooltipShow
      };
    },
    ...mapGetters(["dialogElementShow", "paperGroupId"])
  },
  filters: {
    linkOrigin(link = "") {
      let val = link
        .split("/")
        .filter(item => item.indexOf(":") < 0 && item.trim());
      return val[0];
    },
    fileSize(val = 0) {
      return Math.floor((val / 1024) * 10) / 10 + "kb";
    }
  },
  watch: {
    "dialogElementShow.searchCtrl"(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.xppConSearch.focus();
        });
      }
    }
  },
  data() {
    return {
      searchForm: {
        text: "",
        searchText: "",
        submitState: false
      },
      searchLoad: false,
      activeName: "all",
      searchResult: {},
      searchNull: true,
      hoverDomInfo: null,
      tooltipShow: false,
      searchTooltipText: "",
      searchTooltip: {
        top: "0",
        left: "0"
      }
    };
  },
  methods: {
    // 关闭搜索弹窗
    dialog() {
      // this.searchReset();
      this.$DiaLoginFoUpDate({
        key: "searchCtrl",
        value: false
      });
    },
    // 重置数据状态
    searchReset() {
      this.searchForm.text = "";
      this.searchForm.searchText = "";
      this.searchForm.submitState = false;
      this.activeName = "all";
      this.searchResult = {};
      this.searchNull = true;
    },
    // 跳转到目标页面
    routerJumpPaper(id) {
      this.$DiaLoginFoUpDate();
      if (this.paperGroupId === id) {
        this.$NavToTargetElement();
      } else {
        window.open("/paper/" + id, "_blank", "noopener");
        // this.$router.push({ name: "Paper", params: { id } });
      }
    },
    // 聚焦指定节点
    routerJumpElement({ parentId, elementId, elementType, commentId }) {
      switch (elementType) {
        case "project":
          this.$DiaLoginFoUpDate();
          window.open(`/project/${parentId}`, "_blank", "noopener");
          // location.pathname = "/project/" + parentId;
          break;
        default:
          localStorage.setItem(
            "notiNavigatorInfo",
            JSON.stringify({
              elementId,
              elementType,
              commentId
            })
          );
          this.routerJumpPaper(parentId);
          break;
      }
    },
    // 提交搜索内容
    async searchSubmit(event) {
      event && event.preventDefault();
      if (this.searchForm.text.trim() === "") {
        return;
      }
      this.searchForm.searchText = this.searchForm.text;
      this.searchLoad = true;
      let { list } = await this.$axios.get("/es/paper/search", {
        params: {
          keyword: this.searchForm.text
        }
      });
      this.searchLoad = false;
      this.searchForm.submitState = true;
      this.activeName = "all";
      this.searchResult = {};
      if (!list) {
        return;
      }
      this.searchForm.submitState = true;
      if (!list.length) {
        return;
      }
      for (let i = 0; i < list.length; i++) {
        let item = list[i];
        item.elementType = item.elementType.toLocaleLowerCase();
        switch (item.elementType) {
          case "text":
          case "note":
            item.elementType = "note";
            item.backgroundColor = this.rollColor();
            break;
        }
        if (!this.searchResult[item.elementType]) {
          this.$set(this.searchResult, item.elementType, []);
        }
        if (item.elementType !== "page" && item.elementType !== "comment") {
          this.searchNull = false;
        }
        let div = document.createElement("div");
        div.innerHTML = item.content;
        item.content = div.innerText;
        item.label = item.content.replace(
          new RegExp(this.searchForm.text, "ig"),
          `<span class="xpp-search-tag">${this.searchForm.text}</span>`
        );
        this.searchResult[item.elementType].push(item);
      }
    },
    // 标签切换
    tabsLabel(key) {
      let num;
      if (this.searchResult[key]) {
        num = `(${this.searchResult[key].length})`;
      }
      return num || "";
    },
    // 鼠标悬浮时显示归属
    mousemoveNode({ target }) {
      let node;
      if (target.classList.contains("xpp-search-other-item")) {
        node = target;
      } else {
        node = target.closest(".xpp-search-other-item");
      }
      if (!node) {
        this.tooltipShow = false;
        this.searchTooltipText = "";
        this.searchTooltip.top = "0";
        this.searchTooltip.left = "0";
        this.hoverDomInfo = null;
        return;
      }
      if (node === this.hoverDomInfo) return;
      this.hoverDomInfo = node;
      let offset = node.getBoundingClientRect();
      this.searchTooltipText = node.dataset.group;
      this.searchTooltip.top = offset.top - 40 + "px";
      this.searchTooltip.left =
        offset.left +
        offset.width / 2 -
        this.$refs.tooltip.clientWidth / 2 +
        "px";
      this.tooltipShow = true;
    },
    rollNummer() {
      return Math.floor(Math.random() * 256);
    },
    rollColor() {
      let n1 = this.rollNummer();
      let n2 = this.rollNummer();
      let n3 = this.rollNummer();
      let backgroundColor = "rgb(" + n1 + "," + n2 + "," + n3 + ")";
      // let color = backgroundColor.replace(/rgb(|)/g, "").split(/,\s?/);
      // let color = `rgb(${255 - n1}, ${255 - n2}, ${255 - n3})`;
      let color = n1 * 0.299 + n2 * 0.587 + n3 * 0.114;
      return {
        backgroundColor,
        color: color >= 192 ? "#000" : "#fff"
        // color
      };
    },
    fileDownload(file) {
      location.href = ossDownload(file.name, {
        "content-disposition": `attachment;filename=${file.fileName}`
      });
    },
    ...mapMutations(["$DiaLoginFoUpDate"]),
    ...mapActions(["$NavToTargetElement"])
  }
};
</script>

<style lang="scss">
#xppSearchDialog {
  .el-dialog__header {
    padding-top: 36px;
    background-color: #f4f4f4;
  }
  .el-dialog__body {
    background-color: #f4f4f4;
  }
  .xpp-search-form {
    flex: auto;
  }
  .xpp-search-input {
    width: 50%;
    display: flex;

    .xpp-search-label {
      flex: none;
      font-size: 22px;
      line-height: 40px;
    }
    .el-icon-search {
      font-size: 20px;
    }
    .el-input__inner {
      &:focus + .el-input__suffix {
        opacity: 0;
      }
    }
  }
  .xpp-search-tit {
    font-size: 22px;
    line-height: 46px;
    font-weight: 400;
  }
  .xpp-search-con {
    min-height: 200px;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 20px 30px 0 rgba(194, 198, 209, 0.2);
    border: 1px solid #dbdee6;
    border-radius: 4px;
  }
  .xpp-search-none {
    width: 100%;
    text-align: center;
    line-height: 200px;
    color: #373839;
    font-size: 16px;
  }
  .xpp-searchitem-paper {
    flex: none;
    width: 278px;
    height: 80px;
    margin-right: 20px;
    margin-bottom: 20px;
    border: solid 1px #dbdee6;
    box-shadow: 0 10px 20px 0 rgba(194, 198, 209, 0.2);
  }
  .xpp-search-paper-thumbnail {
    flex: none;
    width: 116px;
    margin-right: 1em;
    border-radius: 4px;
    height: 100%;
    background-color: #f1f1f1;
    max-width: calc(100% - 70px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .xpp-search-item {
    cursor: pointer;
  }
  .xpp-search-other-container {
    margin-top: 35px;
  }
  .xpp-search-other-body {
    background-color: #fff;
    padding: 20px 20px 58px;
    box-shadow: 0 20px 30px 0 rgba(194, 198, 209, 0.2);
    border: 1px solid #dbdee6;
    border-radius: 4px;
    min-height: 200px;
    display: flex;
    flex-wrap: wrap;
  }
  .xpp-search-tag {
    background-color: #f5a623;
    border-radius: 2px;
    line-height: 1.4em;
    color: #fff;
    display: inline-block;
  }
  .xpp-search-other-item {
    flex: none;
    height: 140px;
    width: 200px;
    margin: 0 16px 16px 0;
    background-color: #f3f5f6;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
    overflow: hidden;
    transition: box-shadow 0.2s ease;
    border-radius: 4px;
    cursor: pointer;
    box-sizing: border-box;
    &:hover {
      box-shadow: 0 0 0 3px #f5a623;
    }
  }
  .xpp-user-photo {
    width: 26px;
    height: 26px;
    background-color: #afafaf;
    margin-right: 10px;
    & + div {
      color: #9197a3;
      font-size: 14px;
    }
  }
}
.xpp-search-paper-container {
  .xpp-search-con {
    display: flex;
    flex-wrap: wrap;
  }
}
.xpp-search-paper-inner {
  flex: auto;
  > div {
    max-width: 136px;
  }
}
.xpp-searchitem-note {
  padding: 16px 20px;
  > div {
    font-size: 13px;
    line-height: 1.7;
    max-height: 100%;
    overflow: hidden;
  }
}
.xpp-searchitem-image {
  div {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;
  }
}
.xpp-searchitem-note {
  background-color: #36edc4;
}
.xpp-searchitem-dir {
  div:first-child {
    height: 87px;
    margin-left: 18px;
    margin-right: 18px;
    margin-top: 14px;
    border: 2px solid #ddd;
    background-color: #fff;
    background: url(/img/embed_no_thumbnail.png) no-repeat center;
    background-size: cover;
  }
  div:last-child {
    height: 33px;
    line-height: 33px;
    padding-left: 12px;
    padding-right: 12px;
  }
}
.xpp-searchitem-link,
.xpp-searchitem-file {
  .xpp-searchitem-link-tit {
    padding: 12px 20px;
    background-color: #222;
    color: #bbb;
    text-align: center;
  }
  .xpp-searchitem-link-con {
    position: relative;
    background-color: #222;
    height: calc(100% - 43px);
    background: url(/img/embed_no_thumbnail.png) no-repeat center;
  }
  .xpp-searchitem-link-origin,
  .xpp-searchitem-file-download {
    position: absolute;
    right: 4px;
    bottom: 4px;
    background-color: #444;
    color: #bbb;
    font-size: 12px;
    padding: 7px 9px;
    border-radius: 5px;
    max-width: 100px;
  }
}
.xpp-searchitem-file-tit {
  padding: 12px 20px 0;
  color: #bbb;
  text-align: center;
  background-color: #222;
}
.xpp-searchitem-file-con {
  position: relative;
  background-color: #222;
  text-align: center;
  height: calc(100% - 31px);
  background-color: #222;
  background-position: center 25px;
  background-repeat: no-repeat;
  background-size: 80px;
}
.xpp-searchitem-file-size {
  color: #bbb;
  line-height: 1.5;
}
.xpp-searchitem-file-download:hover {
  background-color: #f5a623;
  color: #fff;
}
.xpp-searchitem-comment {
  background-color: #f8f9fa;
  box-sizing: border-box;
  min-height: 108px;
  margin-bottom: 20px;
  padding: 15px 20px 18px;
  overflow: hidden;
  border: solid 1px #dbdee6;
  box-shadow: 0 10px 20px 0 rgba(194, 198, 209, 0.2);
  > div:first-child {
    height: 32px;
  }
}
.xpp-searchitem-c-time {
  height: 26px;
  color: #9197a3;
  font-size: 12px;
  line-height: 26px;
  font-weight: 600;
}
.xpp-searchitem-c-con {
  padding-left: 36px;
  padding-bottom: 9px;
  font-size: 14px;
  color: #373839;
  line-height: 1.55;
  cursor: pointer;
}
.xpp-searchitem-c-footer {
  padding-left: 36px;
  color: #9197a3;
  line-height: 22px;
  span,
  &::before {
    display: inline-block;
    vertical-align: middle;
  }
  span {
    max-width: 180px;
  }
  &::before {
    content: "";
    width: 16px;
    height: 16px;
    background-image: url(/img/nav-canvas-icon.svg);
    margin-right: 1em;
  }
}

.xpp-search-tooltip {
  position: fixed;
  background-color: #373839;
  font-size: 12px;
  border-radius: 6px;
  color: #fff;
  text-align: center;
  padding: 8px 12px;
  pointer-events: none;
  opacity: 0;
  transform: scale(0);
  z-index: 99999;
  &::after {
    content: "";
    position: absolute;
    bottom: -7px;
    border-color: #373839 transparent;
    left: calc(50% - 10px);
    border-width: 10px 10px 0;
    border-style: solid;
    transition: border-color 0.2s;
  }
  &.xpp-tooltip-show {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
